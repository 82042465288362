import React, { ReactElement } from 'react';
import { Center, Box } from '@chakra-ui/react';
import { Heading } from '../components/Heading';
import { Main } from '../components/Main';

const Page404 = (): ReactElement => (
  <Main>
    <Center minHeight={'calc(100vh - 8em)'}>
      <Box backgroundColor={'ui.surface'} padding={'10%'} textAlign={'center'}>
        <Heading level={1} color={'text.default'} withoutLine margin={0}>
          404
        </Heading>
      </Box>
    </Center>
  </Main>
);

export default Page404;
